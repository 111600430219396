import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validators } from '@angular/forms';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { PostcodeService } from '@src/app/core/services/postcode.service';
import { Address } from '@src/app/models/address';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormComponent } from '../form/form/form.component';
import { add } from 'lodash';

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddressLookupComponent,
      multi: true
    }
  ]
})

export class AddressLookupComponent extends FormComponent implements OnInit, ControlValueAccessor, OnChanges {
  override onSubmit(): void {
    throw new Error('Method not implemented.');
  }
  @Input()
  public address: Address = {} as  Address;
  @Output()
  public addressChanged = new EventEmitter<Address>(); // this is the event that will fire and can be subscribed to in parent components
  public form: FormGroup = new FormGroup({});;
   public formInputPostcode: FormGroup = new FormGroup({});
   public manualEntry: boolean = true;
   public showSearchMessage: boolean = false;

   public editMode: boolean = true;
  public addressId: number=0;
  public postcodeInput: string = ''; // Ensure this matches the template binding
  public addresses : Address[] = [];

  constructor(
    private postcodeService: PostcodeService,
    private loadingSpinnerService: LoadingSpinnerService,
    private fb: FormBuilder
  ) {
    super();
  }

  override ngOnInit(): void {

     this.generateForm();

    this.form.valueChanges.subscribe(value => {
      if(this.address === this.form.value)
        return;
      this.address =  { ...this.address, ...this.form.value };
      this.address.id = 0;  // reset the id to 0 to ensure a new address is created
      this.addressChanged.emit(this.address);
    });
     // The onAddressIdChange method will be called within generateForm to ensure proper setup
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['address']) {
      var newAddress = changes['address'].currentValue;
      let previousAddress = changes['address'].previousValue;
      let isFirstChange = changes['address'].isFirstChange
      if(newAddress && !this.areAddressesEqual(newAddress, previousAddress))
        this.patchAddress(newAddress);
    }
  }

  public generateForm(): void {

    this.formInputPostcode = this.fb.group({
      postcodeInput: [this.postcodeInput],
    });

    if(true || this.address){
    this.form = this.fb.group({
      // Initialize the addressId with the id from the address input if available
      addressId: [this.address?.id || null],
      // postcodeInput: [this.postcodeInput],
      flatNo: [this.address?.flatNo],
      buildingName: [this.address?.buildingName],
      number: [this.address?.number],
      street: [this.address?.street, Validators.required],
      city: [this.address?.city, Validators.required],
      county: [this.address?.county],
      postCode: [this.address?.postCode?.toUpperCase(), Validators.required],
      country: [this.address?.country]
    });
    this.onAddressIdChange(); // Ensure this is called after the form is created
  }
  }

  hasAddressValues(): boolean {
    return this.form.controls['flatNo'].value ||
           this.form.controls['buildingName'].value ||
           this.form.controls['number'].value ||
           this.form.controls['street'].value ||
           this.form.controls['city'].value ||
           this.form.controls['county'].value ||
           this.form.controls['postCode'].value ||
           this.form.controls['country'].value;
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

    onAddressIdChange(): void {
        this.form.get('addressId')?.valueChanges.subscribe((selectedId) => {
          const selectedAddress = this.addresses.find(address => address.fullAddress === selectedId);
          if (selectedAddress) {
            this.address = selectedAddress;
            console.log('address:'  + JSON.stringify(this.address))
            // Set the form values with the selected address details
            this.patchAddress(this.address);
          }
        });
        this.addressChanged.emit(this.address); // Emit the addressId when it changes
      }

      private patchAddress(address: Address): void {
        if(address)
          this.form.patchValue({
            flatNo: address.flatNo,
            buildingName: address.buildingName,
            number: address.number,
            street: address.street,
            city: address.city,
            county: address.county,
            postCode: address.postCode,
            country: address.country
          });
      }

  private areAddressesEqual(address1: Address, address2: Address): boolean {

    return address1?.flatNo === address2?.flatNo &&
           address1?.buildingName === address2?.buildingName &&
           address1?.number === address2?.number &&
           address1?.street === address2?.street &&
           address1?.city === address2?.city &&
           address1?.county === address2?.county &&
           address1?.postCode === address2?.postCode &&
           address1?.country === address2?.country;
  }

  // does a lookup for the address based on the postcode
  onClick(): void {
    this.postcodeInput = this.formInputPostcode.controls["postcodeInput"].value;
    if (this.postcodeInput.length > 0) {
      this.showSearchMessage = false;
      this.loadingSpinnerService.show(); // Show loading indicator

      // Reset the address object
      this.address = {} as Address;
   //   this.patchAddress(this.address); // patch all form fields

        this.postcodeService
          .Lookup(this.postcodeInput)
          .subscribe((addressLookup) => {
            this.showSearchMessage = true;
            addressLookup.addresses.forEach((apiAddress) => {
              apiAddress.latitude = addressLookup.latitude;
              apiAddress.longitude = addressLookup.longitude;
            });

            this.addresses = addressLookup.addresses;

            this.loadingSpinnerService.hide(); // Hide loading indicator once data is loaded

          });

    }
  }


  override validationMessages: { [key: string]: { [key: string]: string } } = {
    street: {
      required: 'Street is required',
    },
    city: {
      required: 'Town is required',
    },
    postcode: {
      required: 'Postcode is required',
    },
  };

  // ControlValueAccessor interface methods
  writeValue(value: any): void {
      if (value !== undefined) {
        this.addressId = value;
        // Update the form control with the new value
        this.form.get('addressId')?.setValue(this.addressId, { emitEvent: false });
      }
    }

    registerOnChange(fn: any): void {
      this.form.get('addressId')?.valueChanges.subscribe(fn);
    }

    registerOnTouched(fn: any): void {
      // You can implement this method if you need to handle the 'touched' state
    }

    setDisabledState?(isDisabled: boolean): void {
      isDisabled ? this.form.disable() : this.form.enable();
    }

    showAddressFields(): boolean {
      return true;  
      return Object.keys(this.form.controls).some(key => key !== 'addressId' && this.form.controls[key].value);
    }

    toggleManualEntry(): void {
      this.manualEntry = !this.manualEntry;
    }
}
