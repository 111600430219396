import { NgModule, ModuleWithProviders } from '@angular/core';
import { UtilityService } from './services/utility.service';

@NgModule({
})
export class UtilityModule {
  constructor() { }
  static forRoot(): ModuleWithProviders<UtilityModule> {
    return {
      ngModule: UtilityModule,
      providers: [
        UtilityService,
      ],
    }
  }
}