<form (ngSubmit)="login()" class="text-center">
  <div class="mb-3">
    <label for="email">Email</label>
    <input type="email" id="email" [(ngModel)]="email" name="email" required class="form-control mx-auto" style="max-width: 300px;">
  </div>
  <div class="mb-3">
    <label for="password">Password</label>
    <div class="input-group" style="max-width: 300px; margin: auto;">
      <input [type]="showPassword ? 'text' : 'password'" id="password" [(ngModel)]="password" name="password" required class="form-control">
      <span class="input-group-text" (click)="togglePasswordVisibility()" role="button">
        <i class="fa" [class.fa-eye]="!showPassword" [class.fa-eye-slash]="showPassword"></i>
      </span>
    </div>
  </div>
  <div class="mb-4" style="max-width: 300px; margin: auto;">
    <button type="submit" class="btn btn-primary w-75">Login</button>
  </div>
</form>

<div class="mb-3" style="max-width: 300px; margin: auto;">
  <a routerLink="/forgot-password" class="btn btn-primary w-75">Forgot Password</a>
</div>
<div class="mb-3" style="max-width: 300px; margin: auto;">
  <a routerLink="/register" class="btn btn-primary w-75">Register now</a>
</div>
<div *ngIf="errors.length > 0" class="mt-3">
  <div class="row justify-content-center">
    <div class="col-10 col-md-8 col-lg-6 col-xl-6">
      <div *ngFor="let error of errors" class="alert alert-danger" role="alert">
        {{error}}
      </div>
    </div>
  </div>
</div>

