<div *ngIf="hasAddressValues() && !editMode">
  <control-wrapper label="Address">
    <div class="row">
      <div class="col-10">
        <div class="card">
          <div class="card-body  bg-light">
            <div *ngFor="let line of address.fullAddress.split(',')">
              <strong>{{ line }}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1">
        <button type="button" (click)="toggleEditMode()" class="btn btn-primary">
          <i class="fa fa-pencil bg-primary"></i>
        </button>

      </div>

    </div>
  </control-wrapper>
</div>

<div *ngIf="!hasAddressValues() || editMode">
  <form [formGroup]="formInputPostcode">
    <control-wrapper name="postcodeLookup" label="Address">
      <div class="input-group ">
        <input class="form-control" formControlName="postcodeInput" placeholder="Enter postcode to search" />
        <span class="input-group-text bg-primary">
          <Button type="submit" (click)="onClick()" class="btn ">
            <i class="fa fa-search text-white"></i>
          </Button>
        </span>
      </div>
      <!-- <div class="mt-2  ">
        <span class="text  text-center float-end">
        <button type="button" (click)="toggleManualEntry()" class="btn btn-primary ">
          <i class="fa fa-pencil"></i> Enter manually
        </button>
      </span>
      </div> -->
    </control-wrapper>
  </form>

  <form [formGroup]="form">
   
    <div *ngIf="addresses.length>0">
      <control-wrapper label="Address matches" [control]="form.controls['addressId']">
        <select class="form-select" formControlName="addressId" title="Select an address from the list"
          (ngModelChange)="onAddressIdChange()" [class.is-invalid]="isControlInvalid('addressId')">
          <option value=""></option>
          <option *ngFor="let address of addresses" [value]="address.fullAddress">{{ address.fullAddress }}</option>
        </select>
        <div *ngIf="isControlInvalid('addressId')" class="invalid-feedback">
          {{ validationErrors['addressId'] }}
        </div>
      </control-wrapper>
    </div>
    
  <hr class="bg-dark border-dark border-4" />
    <div *ngIf="addresses.length===0 && showSearchMessage ">
      <control-wrapper label="&nbsp;">
        <div class="alert alert-warning bg-warning" role="alert">
          No addresses found for the postcode entered
        </div>
      </control-wrapper>
    </div>

    <div *ngIf="showAddressFields() || manualEntry">

      <control-wrapper name="flatNo" label="Flat/building name" [control]="form.controls['flatNo']">
        <input class="form-control" type="text" formControlName="flatNo"
          [class.is-invalid]="isControlInvalid('flatNo')">
        <div *ngIf="isControlInvalid('flatNo')" class="invalid-feedback">
          {{ validationErrors['flatNo'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="buildingName" label="Building Name" [control]="form.controls['buildingName']">
        <input class="form-control" type="text" formControlName="buildingName"
          [class.is-invalid]="isControlInvalid('buildingName')">
        <div *ngIf="isControlInvalid('buildingName')" class="invalid-feedback">
          {{ validationErrors['buildingName'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="number" label="Number" [control]="form.controls['number']">
        <input class="form-control" type="text" formControlName="number"
          [class.is-invalid]="isControlInvalid('number')">
        <div *ngIf="isControlInvalid('number')" class="invalid-feedback">
          {{ validationErrors['number'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="street" label="Street" [control]="form.controls['street']">
        <input class="form-control" type="text" formControlName="street"
          [class.is-invalid]="isControlInvalid('street')">
        <div *ngIf="isControlInvalid('street')" class="invalid-feedback">
          {{ validationErrors['street'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="city" label="City" [control]="form.controls['city']">
        <input class="form-control" type="text" formControlName="city" [class.is-invalid]="isControlInvalid('city')">
        <div *ngIf="isControlInvalid('city')" class="invalid-feedback">
          {{ validationErrors['city'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="county" label="County" [control]="form.controls['county']">
        <input class="form-control" type="text" formControlName="county"
          [class.is-invalid]="isControlInvalid('county')">
        <div *ngIf="isControlInvalid('county')" class="invalid-feedback">
          {{ validationErrors['county'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="postCode" label="PostCode" [control]="form.controls['postCode']">
        <input class="form-control" type="text" formControlName="postCode"
          [class.is-invalid]="isControlInvalid('postCode')">
        <div *ngIf="isControlInvalid('postCode')" class="invalid-feedback">
          {{ validationErrors['postCode'] }}
        </div>
      </control-wrapper>

      <control-wrapper name="country" label="Country" [control]="form.controls['country']">
        <input class="form-control" type="text" formControlName="country"
          [class.is-invalid]="isControlInvalid('country')">
        <div *ngIf="isControlInvalid('country')" class="invalid-feedback">
          {{ validationErrors['country'] }}
        </div>
      </control-wrapper>
    </div>
  </form>
</div>