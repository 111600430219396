import { Injectable } from '@angular/core';
import { AppSettings } from '@core/types/app-settings.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() { }

  public readonly get = (): AppSettings => environment;

  public get apiBaseUri(): string {
    return environment.apiBaseUri;
  }

  public get selectEmptyOption(): string {
    return environment.apiBaseUri;
  }
}
