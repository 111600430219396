<!-- <div class="d-flex justify-content-center align-items-center container " style="height: 100vh;">
  <div class="spinner">
    <img src="/assets/images/spinner.jpg" alt="The Landlord Portal" width="80" height="80">
  </div>
</div> -->

<div class="fullscreen ">
  <div class="container">
    <div class="spinner">
      <img src="/assets/images/spinner.jpg" alt="The Landlord Portal" width="80" height="80">
    </div>
  </div>
</div>