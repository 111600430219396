import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './core/services/user.service';
@Injectable({
  providedIn: 'root'
})
export class RoleBasedPreloadingStrategy implements PreloadingStrategy {
  constructor(private readonly userService: UserService) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data?.['preloadForLandlord'] && this.userService.isInRole('landlord')) {
      console.log('Preloading landlord module');
      return load();
    }
    // testing - should this be tenant ???
    if (route.data?.['preloadForTenant'] && !this.userService.isInRole('landlord')) {
      console.log('Preloading tenant module');
      return load();
    }
    return of(null);
  }
}