<div class="container-fluid h-100">

  <!-- Top header component -->
  <!-- <app-header (toggleSidebar)="toggleSidebar()"></app-header> -->
  <div class="row h-100">
    <!-- Collapsible Sidebar Component -->
    <!-- Adjust the classes to control visibility based on the isSidebarCollapsed property -->

    <app-sidebar [isCollapsed]="isSidebarCollapsed" class="col-md-3 col-lg-2 d-md-block bg-dark sidebar p-0"></app-sidebar>

    <!-- Main content area -->
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" id="main-content">

      <tlp-loading-spinner *ngIf="isLoading"></tlp-loading-spinner>
      <div class="main py-4">
        <router-outlet></router-outlet>
      </div>

    </main>
  </div>
</div>