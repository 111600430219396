
<button *ngIf="isCollapsed" class="bg-primary text-dark float-end rounded-2 m-4 p-2" title="Menu" type="button"
(click)="toggle()" >
<i class="fas fa-bars" style="font-size: 24px; vertical-align: bottom;"></i>
</button>
<nav class="navbar-dark bg-dark sidebar d-flex flex-column sticky-top" *ngIf="!isCollapsed" (click)="onMenuItemClick($event)"
  style-disabled="min-width: 250px;" [ngClass]="{ collapse: isCollapsed, 'text-center':isCollapsed }">
  <div class="position-sticky pt-3">
    <a class="navbar-brand" href="/">
      <img src="/assets/images/the-landlord-portal.png" alt="The Landlord Portal" width="150"
        class="d-inline-block align-text-top" />
    </a>
    <button *ngIf="!isCollapsed && smallScreen" class="bg-primary text-dark float-end rounded-2 m-4 p-2" title="Close"
      type="button" (click)="toggle()">
      <i class="fas fa-times" style="font-size: 24px; vertical-align: bottom;"></i>
    </button>

    <li class="nav-item" *ngIf="currentUser?.isLandlord()">
      <tlp-global-search ></tlp-global-search>
    </li>

    <tlp-landlord-sidebar *ngIf="currentUser?.isLandlord()"></tlp-landlord-sidebar>
    <!-- <tlp-rent-and-bills [isLandlord]="currentUser?.isTenant() ?? false"></tlp-rent-and-bills> -->
    <tlp-tenant-sidebar *ngIf="currentUser?.isTenant()"></tlp-tenant-sidebar>

    <li class="nav-item ">
      <a class="nav-link" routerLink="/messages">
        <span class="nav-link-icon"><span class="fa fa-envelope "></span></span>
        <span class="nav-link-text ps-2">Messages</span>
      </a>
    </li>
  </div>

  <div class="mt-auto">
    <ul class="nav flex-column">
    <li class="nav-item bottom-nav-item" id="adminMenu" [collapse]="isAdminMenuCollapsed">
      <a class="nav-link" routerLink="/system-settings" (click)="onMenuItemClick()">
        <span class="nav-link-icon"><span class="fa fa-desktop"></span></span>
        <span class="nav-link-text ps-2">System</span>
      </a>
      <a class="nav-link" routerLink="users" (click)="onMenuItemClick()">
        <span class="nav-link-icon"><span class="fa fa-users"></span></span>
        <span class="nav-link-text ps-2">Users</span>
      </a>
    </li>
    <li class="nav-item bottom-nav-item" *ngIf="currentUser?.isLandlord()">
      <a class="nav-link" (click)="isAdminMenuCollapsed = !isAdminMenuCollapsed"
        [attr.aria-expanded]="!isAdminMenuCollapsed" aria-controls="adminMenu">
        <div class="cursor-pointer">
          <span class="nav-link-icon"><span class="fa fa-cog"></span></span>
          <span class="nav-link-text ps-2">Settings</span>
        </div>
      </a>
    </li>

    <li class="nav-item bottom-nav-item">
      <a class="nav-link" routerLink="/logout" (click)="onMenuItemClick()">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        Log Off
      </a>
      <div class="nav-link">
        <a class="nav-link" routerLink="/settings" (click)="onMenuItemClick()">
          <span class="bg-primary p-2 nav-link-text rounded-2 form-control-plaintext text-white header-template">
            <img class="m-3"
            *ngIf="currentUserProfile?.profileImageKey;" 
            [src]="currentUserProfile?.profileImageKey + '?width=40&height=40'" class="rounded-circle" width="30" height="30" alt="Profile Image" />
            <i class="fa fa-user pe-3 col-1"  *ngIf="!currentUserProfile?.profileImageKey;"></i>
            <span class="col ms-3">
            {{currentUserProfile.firstName | titlecase}} {{currentUserProfile.lastName | titlecase}}
          </span>
          </span>
        </a>
      </div>
    </li>
  </ul>
    </div>
</nav>
