import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

  public static getDateAsSortDate(date: Date): string {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}-${hour}${minute}${second}`;
  }

  // checks if an object has any properties that are not null | undefined
  public static isEmptyOrAllNull(obj: Record<string, any>): boolean {
    if (Object.keys(obj).length === 0) {
      return true; // Object is empty
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && (obj[key] !== null && obj[key] !== undefined)) {
        return false; // Found a non-null property
      }
    }

    return true; // All properties are null
  }

}
